<template>
  <div>
    <Form
      title="Crear academia"
      ref="academyForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.academyForm.form
      const contacts = this.$refs.academyForm.academyContacts
      this.loading = true
      form.categories = form.categories.map((x) => ({
        id_category: x.split("$$")[0],
        id_subcategory: x.split("$$")[1]
      }))

      this.$store
        .dispatch("global/create", {
          payload: {
            coordinator: form.coordinator,
            contacts: JSON.stringify(contacts),
            name: form.name,
            categories: JSON.stringify(form.categories),
            address1: form.address1,
            address2: form.address2,
            email: form.email,
            instagram: form.instagram,
            url_logo: form.url_logo,
            url_field: form.url_field,
            active: !this.visible ? 0 : 1
          },
          route: "/academy/save",
          module: "academy"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/academias")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
